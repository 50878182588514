import React from 'react';
import { Button, Layout, Row, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import type { ErrorInfo, ReactNode } from 'react';

import { logout } from './components/pages/Login/authSlice';
import { validateAsFirstAlertsRole } from './helpers/utils.helper';
import { RaygunErrorHandlerService } from './service/raygun.service';
import store from './store';

const { Link } = Typography;

const { logError } = RaygunErrorHandlerService();

type Props = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.goBackLogin = this.goBackLogin.bind(this);
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, ['ErrorBoundary', errorInfo.componentStack.split(' ')[1] || '']); // like: "in ComponentName ..."
  }

  goBackLogin() {
    this.setState({ hasError: false });
    store.dispatch(logout());
  }

  render() {
    if (this.state.hasError) {
      const isFirstAlerts = validateAsFirstAlertsRole(store.getState().authSlice.currentUser.role);

      return (
        <Layout>
          <Content className="loginPage">
            <div className="loginCard">
              <div className="loginForm">
                <img
                  src={isFirstAlerts ? 'assets/first-alerts-logo.svg' : 'assets/talkingstick_logo_full_1200.png'}
                  className="loginLogo"
                  alt={`${isFirstAlerts ? 'FirstAlerts' : 'Talking Stick'} logo`}
                />
                <Row className="ErrorHeader">
                  <b>Oops something went wrong.</b>
                  <span style={{ marginTop: 10, marginBottom: 20 }}>Please try again in a few minutes.</span>
                </Row>
                <Row>
                  <Button type="primary" onClick={this.goBackLogin}>
                    Logout
                  </Button>
                </Row>
                <br />
                <span>
                  Having trouble?{' '}
                  <Link underline href="mailto:support@trycycle.ca">
                    Contact us
                  </Link>
                </span>
              </div>
            </div>
          </Content>
        </Layout>
      );
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
