import type { TablePaginationConfig } from 'antd';
import { notification } from 'antd';
import type { SorterResult, SortOrder } from 'antd/lib/table/interface';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type AdvocateFeedback = {
  chat_id: string;
  timestamp: string;
  feedback: string | null;
  advocate_name: string;
  training: string;
  total_count: number;
  rating: string;
  org_name: string;
};

type AdvocateFeedbackParam = {
  pagination: TablePaginationConfig;
  sortedInfo: AdvocateFeedbackSortedInfo;
  filteredInfo: FilteredInfo;
};

type Rating = {
  chat_id: string;
  value: string;
};

export class AdvocateFeedbackSortedInfo implements SorterResult<AdvocateFeedback> {
  columnKey: keyof AdvocateFeedback = 'timestamp';
  field: keyof AdvocateFeedback = 'timestamp';
  order: SortOrder = 'descend';
}

type AdvocateFeedbackSliceType = {
  advocateFeedback: AdvocateFeedback[];
  advocateFeedbackParam: AdvocateFeedbackParam;
  apiStatus: APIStatus;
};

const initialState: AdvocateFeedbackSliceType = {
  advocateFeedback: [],
  advocateFeedbackParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new AdvocateFeedbackSortedInfo(),
    filteredInfo: null,
  },
  apiStatus: APIStatus.IDLE,
};

export const changeAdvocateFeedbackRating = createAsyncThunk<string, Rating, AsyncThunkConfig>(
  'advocateFeedback/changeAdvocateFeedbackRating',
  async (rating: Rating, thunkAPI) => {
    try {
      const response = await axios.post('/v0_set_advocate_feedback_rating', rating);
      thunkAPI.dispatch(fetchAdvocateFeedback());
      notification.success({
        message: 'Success',
        description: 'Rating has been sucessfully changed.',
      });
      return response as unknown as string; // chat_id
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
      logError(e, ['advocateFeedbackSlice', 'changeAdvocateFeedbackRating']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);
export const fetchAdvocateFeedback = createAsyncThunk<AdvocateFeedback[], undefined, AsyncThunkConfig>(
  'advocateFeedback/fetchAdvocateFeedback',
  async (_, thunkAPI) => {
    try {
      const { advocateFeedbackSlice } = thunkAPI.getState();
      const response = await axios.post('v0_get_advocate_feedback', advocateFeedbackSlice.advocateFeedbackParam);
      return response as unknown as AdvocateFeedback[];
    } catch (e) {
      logError(e, ['advocateFeedbackSlice', 'fetchAdvocateFeedback']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const advocateFeedbackSlice = createSlice({
  name: 'advocateFeedback',
  initialState,
  reducers: {
    handleFeedbackTableChange: (state, action: PayloadAction<TableChange<AdvocateFeedback>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as AdvocateFeedbackSortedInfo;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.advocateFeedbackParam.pagination = action.payload.pagination;
      state.advocateFeedbackParam.sortedInfo = sorter;
      state.advocateFeedbackParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.advocateFeedbackParam.pagination = initialState.advocateFeedbackParam.pagination;
      state.advocateFeedbackParam.sortedInfo = initialState.advocateFeedbackParam.sortedInfo;
      state.advocateFeedbackParam.filteredInfo = initialState.advocateFeedbackParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvocateFeedback.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdvocateFeedback.fulfilled, (state, action) => {
        state.advocateFeedback = action.payload;
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdvocateFeedback.rejected, (state, _action) => {
        state.apiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleFeedbackTableChange, clearFilter } = advocateFeedbackSlice.actions;
