import { environment } from '../../../environment/environment';
import CryptoService from '../../../service/crypto.service';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { StateType } from '../../../store';

const { logError } = RaygunErrorHandlerService();

export const loadPersistedAuth = async () => {
  const encryptedAuth = localStorage.getItem(environment.authKey);

  try {
    if (encryptedAuth) {
      const decryptedAuth = await CryptoService.decrypt(encryptedAuth, environment.cryptoKey);
      const authState = JSON.parse(decryptedAuth) as StateType['authSlice'];
      return authState;
    }
  } catch (error) {
    logError(error, ['login.helper', 'loadPersistedAuth']);
    localStorage.removeItem(environment.authKey);
  }
  return undefined;
};

export const getRememberedEmail = async () => {
  const encryptedEmail = localStorage.getItem('rememberedEmail');
  try {
    if (encryptedEmail) {
      const decryptedEmail = await CryptoService.decrypt(encryptedEmail, environment.cryptoKey);
      return decryptedEmail;
    }
  } catch (e) {
    logError(e, ['login.helper', 'getRememberedEmail']);
  }
  return '';
};

export const saveRememberedEmail = async (email: string) => {
  try {
    const encryptedEmail = await CryptoService.encrypt(email, environment.cryptoKey);
    localStorage.setItem('rememberedEmail', encryptedEmail);
  } catch (error) {
    logError(error, ['login.helper', 'saveRememberedEmail']);
  }
};

export const removeRememberedEmail = () => {
  localStorage.removeItem('rememberedEmail');
};
