import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { dateTimeSubmitFormat } from './downloads.helper';

const { logError } = RaygunErrorHandlerService();

class TableRequestParams {
  start_date: Dayjs = dayjs().subtract(1, 'month');
  end_date: Dayjs = dayjs();
}

export type Downloads = {
  date: string;
  iOS_downloads: number;
  android_downloads: number;
};

export type DownloadEntry = {
  date: Dayjs;
  platform: string;
  count: number;
  comment: string;
};

type DownloadsSliceType = {
  tableParams: TableRequestParams;
  downloads: Downloads[];
  tableApiStatus: APIStatus;
};

const initialState: DownloadsSliceType = {
  tableParams: new TableRequestParams(),
  downloads: [],
  tableApiStatus: APIStatus.IDLE,
};

export const fetchDownloads = createAsyncThunk<Downloads[], undefined, AsyncThunkConfig>(
  'downloads/fetchDownloads',
  async (_, thunkAPI) => {
    try {
      const { downloadsSlice } = thunkAPI.getState();
      const start_date = downloadsSlice.tableParams.start_date.startOf('day').format(dateTimeSubmitFormat);
      const end_date = downloadsSlice.tableParams.end_date.endOf('day').format(dateTimeSubmitFormat);
      const response = await axios.post('v0_downloads', {
        start_date,
        end_date,
      });
      return response as unknown as Downloads[];
    } catch (e) {
      logError(e, ['downloadsSlice', 'fetchDownloads']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const addDownloadEntry = createAsyncThunk<string, DownloadEntry, AsyncThunkConfig>(
  'downloads/addDownloadEntry',
  async (downloadEntry, thunkAPI) => {
    try {
      const response = await axios.post('v0_save_download', {
        ...downloadEntry,
        date: downloadEntry.date.startOf('day').format('YYYY-MM-DD'),
      });
      return response as unknown as string;
    } catch (e) {
      logError(e, ['downloadsSlice', 'addDownloadEntry']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const downloadsSlice = createSlice({
  name: 'downloads',
  initialState,
  reducers: {
    handleParamsChange: (state, action: PayloadAction<Partial<TableRequestParams>>) => {
      state.tableParams = { ...state.tableParams, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDownloads.pending, (state, _action) => {
        state.tableApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchDownloads.fulfilled, (state, action) => {
        state.downloads = action.payload;
        state.tableApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchDownloads.rejected, (state, _action) => {
        state.tableApiStatus = APIStatus.ERROR;
      })
      .addCase(addDownloadEntry.fulfilled, (_state, _action) => {
        notification.success({ message: 'Success', description: 'Submited download entry' });
      });
  },
});

export const { handleParamsChange } = downloadsSlice.actions;
