import { Org } from './enum';
import { appName, isBurns } from './helpers/utils.helper';

const orgCode = isBurns ? Org.BurnsWay : Org.Saskatchewan;

const documentFragment = document.createDocumentFragment();

const manifestLink = document.createElement('link');
manifestLink.rel = 'manifest';
manifestLink.href = `/manifest-${orgCode}.json`;
documentFragment.appendChild(manifestLink);

const faviconLink = document.createElement('link');
faviconLink.rel = 'shortcut icon';
faviconLink.type = 'image/webp';
faviconLink.href = `/assets/icons/icon-${orgCode}-48.webp`;
documentFragment.appendChild(faviconLink);

const title = document.createElement('title');
const titleContent = appName;
title.text = titleContent;
documentFragment.appendChild(title);

const appleTouchIconLink = document.createElement('link');
appleTouchIconLink.rel = 'apple-touch-icon';
appleTouchIconLink.type = 'image/webp';
appleTouchIconLink.href = `/assets/icons/icon-${orgCode}-192.webp`;
documentFragment.appendChild(appleTouchIconLink);

document.head.appendChild(documentFragment);
