import type { FilterDropdownProps, FilterValue, Key, SorterResult, SortOrder } from 'antd/es/table/interface';
import type { ColumnType, TablePaginationConfig } from 'antd/lib/table';

export type GetColumnSearchProps = <T>(
  dataIndex: string,
  title: string,
  savedSearch?: string[],
) => Pick<ColumnType<T>, 'filterDropdown' | 'filterIcon' | 'onFilterDropdownOpenChange' | 'render'>;

export type FilteredInfo = Record<string, string[]>;
export type SelectedKeys = FilterDropdownProps['selectedKeys'];
export type Confirm = FilterDropdownProps['confirm'];
export type ClearFilters = FilterDropdownProps['clearFilters'];
export type TableChange<T> = {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<T> | SorterResult<T>[];
};
export type RequestParams<T extends object> = {
  pagination: TablePaginationConfig;
  sortedInfo: SortedInfo<T>;
  filteredInfo: FilteredInfo;
};

interface ISortedInfo<T> extends SorterResult<T> {
  columnKey: Key;
  field: Key;
  order: SortOrder;
}

export class SortedInfo<T extends object> implements ISortedInfo<T> {
  public columnKey: Key;
  public field: Key;

  constructor(
    key: keyof T,
    public order: SortOrder = 'ascend',
  ) {
    this.columnKey = key as Key;
    this.field = key as Key;
    this.order = order;
  }
}
