import { useEffect } from 'react';
import { Modal } from 'antd';

const { confirm } = Modal;

export const useCheckOrientation = () => {
  const checkOrientation = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (window.innerHeight > window.innerWidth && isMobile) {
      confirm({
        title: '',
        icon: '',
        centered: true,
        cancelButtonProps: { style: { display: 'none' } },
        content: `For the best experience,
      please rotate your phone sideways to Landscape mode`,
        onOk() {},
        onCancel() {},
      });
    }
  };

  useEffect(() => {
    checkOrientation();
  }, []);
};
