import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type StatusMatch = 'Blocked' | 'Reviewed' | 'Waiting';

export type AbusiveGuest = {
  abusive_chats: number;
  guest_id: string;
  blocked_updated: string;
  guest_blocked: StatusMatch;
  total_points: number;
  guest_alias: string;
  total_count: number;
  org_name: string;
};

type GuestStatus = {
  guest_id: string;
  blocked: boolean | null;
};

type AbusiveGuestsSliceType = {
  abusiveGuests: AbusiveGuest[];
  abusiveGuestsParam: RequestParams<AbusiveGuest>;
  apiStatus: APIStatus;
};

const initialState: AbusiveGuestsSliceType = {
  abusiveGuests: [],
  abusiveGuestsParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AbusiveGuest>('total_points', 'descend'),
    filteredInfo: { guest_blocked: ['null'] },
  },
  apiStatus: APIStatus.IDLE,
};

export const fetchAbusiveGuests = createAsyncThunk<AbusiveGuest[], undefined, AsyncThunkConfig>(
  'abusiveGuests/fetchAbusiveGuests',
  async (_, thunkAPI) => {
    try {
      const { abusiveGuestsSlice } = thunkAPI.getState();
      const response = await axios.post('v0_get_abusive_guests', abusiveGuestsSlice.abusiveGuestsParam);
      return response as unknown as AbusiveGuest[];
    } catch (e) {
      logError(e, ['abusiveGuestsSlice', 'fetchAbusiveGuests']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const changeGuestStatus = createAsyncThunk<string, GuestStatus, AsyncThunkConfig>(
  'abusiveGuests/changeGuestStatus',
  async (status: GuestStatus, thunkAPI) => {
    try {
      const response = await axios.post('/v0_set_guest_blocked_status', status);
      thunkAPI.dispatch(fetchAbusiveGuests());
      notification.success({
        message: 'Success',
        description: 'Status has been sucessfully changed.',
      });
      return response as unknown as string;
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
      logError(e, ['abusiveGuestsSlice', 'changeGuestStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const abusiveGuestsSlice = createSlice({
  name: 'abusiveGuests',
  initialState,
  reducers: {
    handleAbusiveGuestTableChange: (state, action: PayloadAction<TableChange<AbusiveGuest>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AbusiveGuest>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.abusiveGuestsParam.pagination = action.payload.pagination;
      state.abusiveGuestsParam.sortedInfo = sorter;
      state.abusiveGuestsParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.abusiveGuestsParam.pagination = initialState.abusiveGuestsParam.pagination;
      state.abusiveGuestsParam.sortedInfo = initialState.abusiveGuestsParam.sortedInfo;
      state.abusiveGuestsParam.filteredInfo = initialState.abusiveGuestsParam.filteredInfo;
    },
    handleNavigationPageChange: (state, action: PayloadAction<FilteredInfo>) => {
      state.abusiveGuestsParam.filteredInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbusiveGuests.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAbusiveGuests.fulfilled, (state, action) => {
        state.abusiveGuests = action.payload;
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAbusiveGuests.rejected, (state, _action) => {
        state.apiStatus = APIStatus.ERROR;
      })
      .addCase(changeGuestStatus.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(changeGuestStatus.fulfilled, (state, _action) => {
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(changeGuestStatus.rejected, (state, _action) => {
        state.apiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleAbusiveGuestTableChange, clearFilter, handleNavigationPageChange } = abusiveGuestsSlice.actions;
