import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { Role } from '../../../enum';
import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export class SuperAdmin {
  id: string | undefined = undefined;
  full_name: string | undefined = undefined;
  first_name: string | undefined = undefined;
  last_name: string | undefined = undefined;
  email: string | undefined = undefined;
  created: string | undefined = undefined;
  role: Role | undefined = undefined;
  account_status: string | undefined = undefined;
  total_count?: number | undefined = undefined;
}

type SuperAdminSliceType = {
  superAdmins: SuperAdmin[];
  requestSuperAdminParam: RequestParams<SuperAdmin>;
  superAdminApiStatus: APIStatus;
  superAdmin: SuperAdmin;
};

const initialState: SuperAdminSliceType = {
  superAdmins: [],
  requestSuperAdminParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<SuperAdmin>('full_name', 'ascend'),
    filteredInfo: {},
  },
  superAdminApiStatus: APIStatus.IDLE,
  superAdmin: new SuperAdmin(),
};

export const fetchSuperAdmins = createAsyncThunk<SuperAdmin[], undefined, AsyncThunkConfig>(
  'superAdmin/fetchSuperAdmins',
  async (_, thunkAPI) => {
    try {
      const { requestSuperAdminParam } = thunkAPI.getState().superAdminSlice;
      const response = (await axios.post('v0_get_admins', requestSuperAdminParam)) as SuperAdmin[];
      return response ?? [];
    } catch (e) {
      logError(e, ['superAdminSlice', 'fetchsuperAdmins']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    handleSuperAdminTableChange: (state, action: PayloadAction<TableChange<SuperAdmin>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<SuperAdmin>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestSuperAdminParam.pagination = action.payload.pagination;
      state.requestSuperAdminParam.sortedInfo = sorter;
      state.requestSuperAdminParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestSuperAdminParam.pagination = initialState.requestSuperAdminParam.pagination;
      state.requestSuperAdminParam.sortedInfo = initialState.requestSuperAdminParam.sortedInfo;
      state.requestSuperAdminParam.filteredInfo = initialState.requestSuperAdminParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuperAdmins.pending, (state, _action) => {
        state.superAdminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchSuperAdmins.fulfilled, (state, action) => {
        state.superAdmins = action.payload;
        state.superAdminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchSuperAdmins.rejected, (state, _action) => {
        state.superAdminApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleSuperAdminTableChange, clearFilter } = superAdminSlice.actions;
