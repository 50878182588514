import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { useAppDispatch } from '../../../hooks';
import { restoreAuthState } from './authSlice';
import { loadPersistedAuth } from './login.helper';

const AuthPersist = ({ children }: { children: ReactNode }) => {
  const [ready, setReady] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getPersistedState = async () => {
      const authState = await loadPersistedAuth();
      dispatch(restoreAuthState(authState));
      setReady(true);
    };
    getPersistedState();
  }, [dispatch]);

  if (!ready) {
    return null;
  }

  return <>{children}</>;
};

export default AuthPersist;
