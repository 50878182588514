import { Suspense } from 'react';
import { Spin } from 'antd';
import type { ReactNode } from 'react';

import ErrorBoundary from '../errorBoundary';

export const SuspenseRoute = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spin style={{ marginTop: '25%' }} />}>{children}</Suspense>
    </ErrorBoundary>
  );
};
