import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { redirect } from 'react-router-dom';

import MainLayout from '../components/layouts/layout';
import { AdvocateUpsert, setAdvocate } from '../components/pages/advocate-upsert/advocateUpsertSlice';
import { resetPasswordLoaderWithStore } from '../components/pages/reset-password/reset-password.helper';
import { Role } from '../enum';
import ErrorBoundary from '../errorBoundary';
import store from '../store';
import RequireAuth from './RequireAuth';
import { SuspenseRoute } from './SuspendeRoute';

const LoginComponent = lazy(() => import('../components/pages/Login/login'));
const ForgotPasswordComponent = lazy(() => import('../components/pages/ForgotPassword/forgot'));
const ResetPasswordComponent = lazy(() => import('../components/pages/reset-password/reset-password'));
const ProgramDashboardComponent = lazy(() => import('../components/pages/program-dashboard/program-dashboard'));
const AdvocatesOnlineDashboardComponent = lazy(
  () => import('../components/pages/advocates-online-dashboard/advocates-online-dashboard'),
);
const AdminAdvocateProfileComponent = lazy(() => import('../components/pages/advocate-profile/admin-advocate-profile'));
const AdminCommunityComponent = lazy(() => import('../components/pages/admin-community/admin-community'));
const AdminFirstAlertsCommunityComponent = lazy(
  () => import('../components/pages/admin-first-alerts-communities/admin-first-alerts-communities'),
);
const AdvocateUpsertComponent = lazy(() => import('../components/pages/advocate-upsert/advocate-upsert'));
const AdminAdvocatesComponent = lazy(() => import('../components/pages/admin-advocates/admin-advocates'));
const AdminManagersComponent = lazy(() => import('../components/pages/admin-managers/admin-managers'));
const SuperAdminsComponent = lazy(() => import('../components/pages/super-admin/super-admins'));
const DownloadsComponent = lazy(() => import('../components/pages/downloads/downloads'));
const AdminAdvocateHoursComponent = lazy(
  () => import('../components/pages/admin-advocates-hours/admin-advocates-hours'),
);
const TimesheetComponent = lazy(() => import('../components/pages/timesheets/timesheets'));
const GuestFeedbackComponent = lazy(() => import('../components/pages/guest-feedback/guestFeedback'));
const AdvocateFeedbackComponent = lazy(() => import('../components/pages/advocate-feedback/advocateFeedback'));
const ReportOfAbuseComponent = lazy(() => import('../components/pages/report-of-abuse/report-of-abuse'));
const AlertComponent = lazy(() => import('../components/pages/alerts/alert'));
const AbusiveGuestsComponent = lazy(() => import('../components/pages/abusive-guests/abusive-guests'));
const ManagerSettingsComponent = lazy(() => import('../components/pages/manager-settings/manager-settings'));

export const routes: RouteObject[] = [
  { index: true, loader: () => redirect('/page/program-dashboard') },
  { path: '*', loader: () => redirect('/page/program-dashboard') },
  {
    path: '/login',
    element: (
      <SuspenseRoute>
        <LoginComponent />
      </SuspenseRoute>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <SuspenseRoute>
        <ForgotPasswordComponent />
      </SuspenseRoute>
    ),
  },
  {
    path: '/auth/reset/:token',
    element: (
      <SuspenseRoute>
        <ResetPasswordComponent />
      </SuspenseRoute>
    ),
    loader: resetPasswordLoaderWithStore(),
  },
  {
    path: '/page',
    element: (
      <ErrorBoundary>
        <RequireAuth requiredRoles={Object.values(Role)}>
          <MainLayout />
        </RequireAuth>
      </ErrorBoundary>
    ),
    children: [
      { index: true, loader: () => redirect('program-dashboard') },
      {
        path: '*',
        loader: () => redirect('program-dashboard'),
      },
      {
        path: 'program-dashboard',
        element: (
          <SuspenseRoute>
            <RequireAuth
              requiredRoles={[
                Role.SUPER_ADMIN,
                Role.EXTERNAL_ADMIN,
                Role.DIRECTOR,
                Role.MANAGER,
                Role.DASHBOARD_ONLY,
                Role.SERVICE_PROVIDER_MANAGER,
              ]}
            >
              <ProgramDashboardComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'advocates-online-dashboard',
        element: (
          <SuspenseRoute>
            <RequireAuth
              requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER, Role.DASHBOARD_ONLY]}
            >
              <AdvocatesOnlineDashboardComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-advocate-profile/:advocate_id',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER]}>
              <AdminAdvocateProfileComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-service-provider-profile/:advocate_id',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.SERVICE_PROVIDER_MANAGER]}>
              <AdminAdvocateProfileComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-communities',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER]}>
              <AdminCommunityComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'first-alerts-communities',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR]}>
              <AdminFirstAlertsCommunityComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'alerts',
        element: (
          <SuspenseRoute>
            <RequireAuth
              requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.ALERT_MANAGER, Role.ALERT_DISPATCHER]}
            >
              <AlertComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'advocate-onboarding',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER]}>
              <AdvocateUpsertComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
        loader: () => {
          store.dispatch(setAdvocate(new AdvocateUpsert()));
          return null;
        },
      },
      {
        path: 'service-provider-onboarding',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.SERVICE_PROVIDER_MANAGER]}>
              <AdvocateUpsertComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
        loader: () => {
          store.dispatch(setAdvocate(new AdvocateUpsert()));
          return null;
        },
      },
      {
        path: 'admin-advocates',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER]}>
              <AdminAdvocatesComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-service-providers',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.SERVICE_PROVIDER_MANAGER]}>
              <AdminAdvocatesComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-managers',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR]}>
              <AdminManagersComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-service-providers-managers',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.SERVICE_PROVIDER_MANAGER]}>
              <AdminManagersComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-first-alerts-managers',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.ALERT_MANAGER]}>
              <AdminManagersComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'admin-advocates-hours',
        element: (
          <SuspenseRoute>
            <RequireAuth
              requiredRoles={[
                Role.SUPER_ADMIN,
                Role.EXTERNAL_ADMIN,
                Role.DIRECTOR,
                Role.MANAGER,
                Role.SERVICE_PROVIDER_MANAGER,
              ]}
            >
              <AdminAdvocateHoursComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'super-admins',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN]}>
              <SuperAdminsComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'timesheets',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER]}>
              <TimesheetComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'manager-settings',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={Object.values(Role)}>
              <ManagerSettingsComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'advocates',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.MANAGER]}>
              <AdminAdvocatesComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'guest-feedback',
        element: (
          <SuspenseRoute>
            <RequireAuth
              requiredRoles={[
                Role.SUPER_ADMIN,
                Role.EXTERNAL_ADMIN,
                Role.DIRECTOR,
                Role.MANAGER,
                Role.SERVICE_PROVIDER_MANAGER,
              ]}
            >
              <GuestFeedbackComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'advocate-feedback',
        element: (
          <SuspenseRoute>
            <RequireAuth
              requiredRoles={[
                Role.SUPER_ADMIN,
                Role.EXTERNAL_ADMIN,
                Role.DIRECTOR,
                Role.MANAGER,
                Role.SERVICE_PROVIDER_MANAGER,
              ]}
            >
              <AdvocateFeedbackComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'reports-of-abuse/:guest_info?',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER]}>
              <ReportOfAbuseComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'abusive-guests/:guest_alias?',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR, Role.MANAGER]}>
              <AbusiveGuestsComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
      {
        path: 'downloads',
        element: (
          <SuspenseRoute>
            <RequireAuth requiredRoles={[Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN]}>
              <DownloadsComponent />
            </RequireAuth>
          </SuspenseRoute>
        ),
      },
    ],
  },
];
