import type { LabeledValue, SelectValue } from 'antd/lib/select';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export const months: LabeledValue[] = [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'February',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

export const reportDatesRange: LabeledValue[] = [
  {
    label: '2 weeks',
    value: '2 weeks',
  },
  {
    label: '1 week',
    value: '1 weeks',
  },
  {
    label: '3 days',
    value: '3 days',
  },
  {
    label: '1 month',
    value: '1 months',
  },
  {
    label: '3 months',
    value: '3 months',
  },
  {
    label: '6 months',
    value: '6 months',
  },
  {
    label: 'All data',
    value: '6 years',
  },
];

export const getDateRangeValue = (
  startDate: Dayjs = dayjs(),
  endDate: Dayjs = dayjs(),
  datesRange: LabeledValue[],
): SelectValue => {
  let result = 'Custom' as SelectValue;

  for (const { value } of datesRange) {
    const [amount, unit] = (value as string).split(' ');
    const endDateToCompare = dayjs(startDate).add(Number(amount), unit as dayjs.ManipulateType);

    if (endDate.isSame(endDateToCompare, 'day')) {
      result = value;
      break;
    }
  }

  return result;
};
