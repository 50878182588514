import type { AxiosError } from 'axios';
import type { RaygunV2UserDetails } from 'raygun4js';
import rg4js from 'raygun4js';

import type { Role } from '../enum';
import { environment } from '../environment/environment';

type RaygunUser = {
  user_id: string;
  role: Role | null;
};

const isLocal = environment.environment_deploy === 'local';
const disabled = ['local', 'ci'].includes(environment.environment_deploy);

rg4js('options', {
  disableErrorTracking: disabled,
});
rg4js('apiKey', environment.rayGunKey);
rg4js('setVersion', `${environment.versionNumber}.${environment.buildNumber}`);
rg4js('enableCrashReporting', !disabled);
rg4js('withTags', [environment.environment_deploy, `v${environment.versionNumber}`]);
rg4js('whitelistCrossOriginDomains', []);
rg4js('filterSensitiveData', ['password', 'pass', 'new_password', 'old_password', 'sin']);

export const RaygunErrorHandlerService = () => {
  const logError = (err: unknown, tags: string[]) => {
    const error = err as AxiosError;
    if (error.response?.status) {
      tags = [error.response.status.toString(), ...tags];
    }

    const data = { error, tags };

    if (!disabled && navigator.onLine) {
      rg4js('send', data);
    }

    if (isLocal && !(error.response && error.response.status === 401)) {
      // eslint-disable-next-line no-console
      console.error(data);
    }
  };

  /**
   * example:
   *
   * rg4js('setUser', {
   *  identifier: 'user_email_address@localhost.local',
   *  isAnonymous: false,
   *  email: 'emailaddress@localhost.local',
   *  firstName: 'Foo',
   *  fullName: 'Foo Bar',
   *  uuid: 'BAE62917-ACE8-ab3D-9287-B6A33B8E8C55'
   * });
   */

  const setRaygunUser = async (user: RaygunUser) => {
    const details: RaygunV2UserDetails = {
      identifier: `Role: ${user.role}, User id: ${user.user_id}`,
    };
    rg4js('setUser', details);
  };

  const resetRaygunUser = () => {
    const value: RaygunV2UserDetails = { identifier: '' };
    rg4js('setUser', value);
  };

  return { logError, setRaygunUser, resetRaygunUser };
};
