import { notification } from 'antd';

import type { AdminFirstAlertsCommunity } from './adminFirstAlertsCommunitiesSlice';

export const validateAdminFirstAlertsCommunity = (adminFirstAlertCommunity: AdminFirstAlertsCommunity) => {
  if (!adminFirstAlertCommunity.name || !adminFirstAlertCommunity.org_code || !adminFirstAlertCommunity.type_id) {
    notification.error({
      message: 'Missing required fields',
      description: 'Please provide values for all required fields.',
    });
    return false;
  } else {
    return true;
  }
};
