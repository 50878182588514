import { notification } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { Middleware, UnknownAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore, isAction } from '@reduxjs/toolkit';

import { advocateSetTempPasswordSlice } from './components/modals/advocateSetTempPassword/advocateSetTempPasswordSlice';
import { abusiveGuestsSlice } from './components/pages/abusive-guests/abusiveGuestsSlice';
import { adminAdvocateSlice } from './components/pages/admin-advocates/adminAdvocateSlice';
import { adminAdvocateHoursSlice } from './components/pages/admin-advocates-hours/adminAdvocatesHoursSlice';
import { adminCommunitySlice } from './components/pages/admin-community/adminCommunitySlice';
import { adminFirstAlertsCommunitiesSlice } from './components/pages/admin-first-alerts-communities/adminFirstAlertsCommunitiesSlice';
import { adminManagerSlice } from './components/pages/admin-managers/adminManagerSlice';
import { advocateFeedbackSlice } from './components/pages/advocate-feedback/advocateFeedbackSlice';
import { advocateUpsertSlice } from './components/pages/advocate-upsert/advocateUpsertSlice';
import { advocatesOnlineDashboardSlice } from './components/pages/advocates-online-dashboard/advocatesOnlineDashboardSlice';
import { alertSlice } from './components/pages/alerts/alertSlice';
import { downloadsSlice } from './components/pages/downloads/downloadsSlice';
import { guestFeedbackSlice } from './components/pages/guest-feedback/guestFeedbackSlice';
import { authSlice, logout, refreshToken } from './components/pages/Login/authSlice';
import { programDashboardSlice } from './components/pages/program-dashboard/programDashboardSlice';
import { reportOfAbuseSlice } from './components/pages/report-of-abuse/reportOfAbuseSlice';
import { resetPasswordSlice } from './components/pages/reset-password/resetPasswordSlice';
import { superAdminSlice } from './components/pages/super-admin/superAdminSlice';
import { timesheetsSlice } from './components/pages/timesheets/timesheetsSlice';
import { environment } from './environment/environment';
import CryptoService from './service/crypto.service';
import { RaygunErrorHandlerService } from './service/raygun.service';
import { tableSearchSlice } from './service/tableSearchSlice';

dayjs.extend(utc);
dayjs.extend(timezone);

const isLocal = environment.environment_deploy === 'local';
const isTestInCI = environment.environment_deploy === 'ci';
const { logError } = RaygunErrorHandlerService();

const reducers = combineReducers({
  adminAdvocateSlice: adminAdvocateSlice.reducer,
  tableSearchSlice: tableSearchSlice.reducer,
  adminManagerSlice: adminManagerSlice.reducer,
  superAdminSlice: superAdminSlice.reducer,
  timesheetsSlice: timesheetsSlice.reducer,
  adminCommunitySlice: adminCommunitySlice.reducer,
  adminFirstAlertCommunitySlice: adminFirstAlertsCommunitiesSlice.reducer,
  advocateUpsertSlice: advocateUpsertSlice.reducer,
  advocateSetTempPasswordSlice: advocateSetTempPasswordSlice.reducer,
  authSlice: authSlice.reducer,
  programDashboardSlice: programDashboardSlice.reducer,
  advocatesOnlineDashboardSlice: advocatesOnlineDashboardSlice.reducer,
  resetPasswordSlice: resetPasswordSlice.reducer,
  guestFeedbackSlice: guestFeedbackSlice.reducer,
  advocateFeedbackSlice: advocateFeedbackSlice.reducer,
  adminAdvocateHoursSlice: adminAdvocateHoursSlice.reducer,
  reportOfAbuseSlice: reportOfAbuseSlice.reducer,
  alertSlice: alertSlice.reducer,
  abusiveGuestsSlice: abusiveGuestsSlice.reducer,
  downloadsSlice: downloadsSlice.reducer,
});

export type StateType = ReturnType<typeof reducers>;

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const rootReducer = (state: StateType | undefined, action: UnknownAction) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type === 'LOG_OUT') {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

const saveAuth = async (state: Pick<StateType, 'authSlice'>) => {
  try {
    const encryptedAuth = await CryptoService.encrypt(JSON.stringify(state.authSlice), environment.cryptoKey);
    localStorage.setItem(environment.authKey, encryptedAuth);
  } catch (error) {
    logError(error, ['store', 'saveAuth']);
  }
};

const tokenRefresh: Middleware = ({
  dispatch,
  getState,
}: {
  dispatch: typeof store.dispatch;
  getState: typeof store.getState;
}) => {
  return (next) => (action) => {
    if (isAction(action) && !action.type.includes('auth/') && process.env.NODE_ENV !== 'test') {
      if (getState().authSlice && getState().authSlice.authResult.token) {
        const tokenExpiration = getState().authSlice.currentUser.exp;
        if (tokenExpiration && new Date(tokenExpiration * 1000).getTime() - new Date().getTime() < 20 * 60 * 1000) {
          return Promise.resolve(dispatch(refreshToken())).then(() => next(action));
        }
      }
    }
    return next(action);
  };
};

axios.defaults.baseURL = environment.baseEndpoint;
axios.defaults.responseType = 'json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['appversion'] = `${environment.versionNumber}.${environment.buildNumber}`;

const Interceptor = (createdStore: typeof store) => {
  const { dispatch } = createdStore;

  axios.interceptors.request.use(
    async (config) => {
      if (!config.data) config.data = {};
      config.headers['timezone_name'] = dayjs.tz.guess();
      config.headers['content-location'] = dayjs.tz.guess();

      const { resetPasswordSlice, authSlice } = createdStore.getState();
      if (
        config.url === 'v0_token_verify' ||
        (resetPasswordSlice.tokenFromPath && config.url === 'v0_reset_password')
      ) {
        return config;
      }

      const token = authSlice.authResult.token;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (next) => Promise.resolve(next.data),
    (error) => {
      const online = navigator.onLine;
      const config = error.response?.config;
      if (!online) {
        notification.error({
          message: 'Offline',
          description: 'No internet connection.',
        });
      } else if (error.response && error.response.status === 401 && config?.url !== 'v0_token_verify') {
        dispatch(logout());
      } else if (!axios.isCancel(error)) {
        let errorTitle = error.message || 'Error';
        let errorDetails = 'Oops. Something went wrong.';
        errorDetails += ' Please contact a system administrator if the problem persists.';
        if (error.response) {
          if (error.response.data && error.response.data.customTitle) {
            errorTitle = error.response.data.customTitle;
          } else if (error.response.status === 422) {
            errorTitle = 'Invalid input';
          } else if (error.response.status === 404) {
            errorTitle = 'Not found';
          }
          if (error.response.data && error.response.data.displayText) {
            errorDetails = error.response.data.displayText;
          }
        }

        const { resetPasswordSlice } = createdStore.getState();

        if (
          !(
            config?.url === 'v0_token_verify' ||
            (resetPasswordSlice.tokenFromPath && config?.url === 'v0_reset_password')
          )
        ) {
          notification.error({
            message: errorTitle,
            description: errorDetails,
            duration: errorDetails.length > 150 ? 8 : 4.5,
          });
        }
      }

      return Promise.reject(error);
    },
  );
};

export const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: !isTestInCI,
      }).concat(tokenRefresh),
    devTools: isLocal,
  });

  Interceptor(store);

  return store;
};

const store = createStore();

let prevState = store.getState().authSlice;
store.subscribe(() => {
  const currentState = store.getState().authSlice;
  if (currentState !== prevState) {
    saveAuth({
      authSlice: currentState,
    });
    prevState = currentState;
  }
});

export default store;
