import type { AxiosError } from 'axios';
import axios from 'axios';
import dayjs, { type Dayjs } from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { Org } from '../../../enum';
import { APIStatus } from '../../../enum';
import type { GuestAlertsRegistered, NameValue } from '../../../models/dashboard';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { dateTimeFilterFormat } from '../program-dashboard/program-dashboard.helper';

const { logError } = RaygunErrorHandlerService();

class AlertDashboardRequestParams {
  start_date: Dayjs = dayjs().subtract(1, 'month');
  end_date: Dayjs = dayjs();
}

export type Alert = {
  total_count: number;
  id: number;
  sent_time: string;
  sent_by: string;
  title: string;
  message: string;
  number_of_devices: number;
  org_name: string;
  internal: boolean;
  first_alerts_community_name: string;
  type: AlertType;
  dart: boolean;
};

type FirstAlertCommunity = {
  id: string;
  name: string;
  type_id: string;
  type_name: string;
  org_code: Org;
};

export const AlertTypeLabel = {
  natural_disaster: 'Natural disaster',
  public_safety_threat: 'Public safety threat',
  missing_person: 'Missing person',
} as const;

export type AlertType = keyof typeof AlertTypeLabel;

export type AlertParams = {
  type: AlertType | undefined;
  title: string;
  message: string;
  dart: boolean;
  first_alerts_community_id: string | undefined;
};

type AlertDashboardData = {
  start_date: Dayjs;
  end_date: Dayjs;
  total_guest_alerts_registered: number | null;
  total_alerts_sent: number | null;
  total_alerts_sent_to: number | null;
  first_alerts_types_sent: NameValue[];
  guest_alerts_registered: GuestAlertsRegistered | null;
};

type AlertSliceType = {
  alerts: Alert[];
  dartEndTimestamp: string;
  firstAlertsCommunities: FirstAlertCommunity[];
  requestAlertsParams: RequestParams<Alert>;
  alertsApiStatus: APIStatus;
  sendAlertApiStatus: APIStatus;
  showModal: boolean;

  alertsStatsApiStatus: APIStatus;
  alertDashboardParams: AlertDashboardRequestParams;
  alertDashboardData: AlertDashboardData;
};

const initialState: AlertSliceType = {
  alerts: [],
  dartEndTimestamp: '',
  firstAlertsCommunities: [],
  requestAlertsParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<Alert>('sent_time', 'descend'),
    filteredInfo: {},
  },
  alertsApiStatus: APIStatus.IDLE,
  sendAlertApiStatus: APIStatus.IDLE,
  showModal: false,

  alertsStatsApiStatus: APIStatus.IDLE,
  alertDashboardParams: new AlertDashboardRequestParams(),
  alertDashboardData: {
    start_date: dayjs().subtract(1, 'month'),
    end_date: dayjs(),
    total_guest_alerts_registered: null,
    total_alerts_sent: null,
    total_alerts_sent_to: null,
    first_alerts_types_sent: [],
    guest_alerts_registered: null,
  },
};

export const fetchAlerts = createAsyncThunk<Alert[], undefined, AsyncThunkConfig>(
  'alert/fetchAlerts',
  async (_, thunkAPI) => {
    try {
      const { requestAlertsParams } = thunkAPI.getState().alertSlice;
      const response = (await axios.post('v0_get_logged_alerts', requestAlertsParams)) as Alert[];
      return response ?? [];
    } catch (e) {
      logError(e, ['alertSlice', 'fetchAlerts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const sendAlert = createAsyncThunk<string, Required<AlertParams>, AsyncThunkConfig>(
  'alert/sendAlert',
  async (alert, thunkAPI) => {
    try {
      const response = (await axios.post('v0_send_alert_notifications', alert)) as string;
      if (alert.dart) {
        thunkAPI.dispatch(fetchDartDeadline());
      }
      return response ?? ''; // id
    } catch (e) {
      logError(e, ['alertSlice', 'sendAlert']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getFirstAlertsStats = createAsyncThunk<AlertDashboardData, undefined, AsyncThunkConfig>(
  'alert/getFirstAlertsStats',
  async (_, thunkAPI) => {
    try {
      const { start_date, end_date } = thunkAPI.getState().alertSlice.alertDashboardParams;
      const response = await axios.post('v0_get_first_alerts_statistics', {
        start_date: start_date.startOf('day').format(dateTimeFilterFormat),
        end_date: end_date.endOf('day').format(dateTimeFilterFormat),
      });
      return response as unknown as AlertDashboardData;
    } catch (e) {
      logError(e, ['alertSlice', 'getFirstAlertsStats']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAdminFirstAlertsCommunitiesForForm = createAsyncThunk<
  FirstAlertCommunity[],
  undefined,
  AsyncThunkConfig
>('alert/fetchAdminFirstAlertsCommunitiesForForm', async (_, thunkAPI) => {
  try {
    const response = (await axios.post('v0_get_admin_first_alerts_communities')) as FirstAlertCommunity[];
    return response ?? [];
  } catch (e) {
    logError(e, ['alertSlice', 'fetchAdminFirstAlertsCommunitiesForForm']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const fetchDartDeadline = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'alert/fetchDartDeadline',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v0_get_dart_deadline')) as string;
      return response ?? '';
    } catch (e) {
      logError(e, ['alertSlice', 'fetchDartDeadline']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    handleAlertsTableChange: (state, action: PayloadAction<TableChange<Alert>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<Alert>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAlertsParams.pagination = action.payload.pagination;
      state.requestAlertsParams.sortedInfo = sorter;
      state.requestAlertsParams.filteredInfo = removeEmpty(filters);
    },
    handleParamsChange: (state, action: PayloadAction<Partial<AlertDashboardRequestParams>>) => {
      state.alertDashboardParams = { ...state.alertDashboardParams, ...action.payload };
    },
    clearFilter: (state) => {
      state.requestAlertsParams.pagination = initialState.requestAlertsParams.pagination;
      state.requestAlertsParams.sortedInfo = initialState.requestAlertsParams.sortedInfo;
      state.requestAlertsParams.filteredInfo = initialState.requestAlertsParams.filteredInfo;
    },
    showAlertModal: (state) => {
      state.showModal = true;
    },
    closeAlertModal: (state) => {
      state.showModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlerts.pending, (state, _action) => {
        state.alertsApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        state.alerts = action.payload;
        state.alertsApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAlerts.rejected, (state, _action) => {
        state.alertsApiStatus = APIStatus.ERROR;
      })
      .addCase(sendAlert.pending, (state, _action) => {
        state.sendAlertApiStatus = APIStatus.PENDING;
      })
      .addCase(sendAlert.fulfilled, (state, _action) => {
        state.sendAlertApiStatus = APIStatus.FULFILLED;
      })
      .addCase(sendAlert.rejected, (state, _action) => {
        state.sendAlertApiStatus = APIStatus.ERROR;
      })
      .addCase(getFirstAlertsStats.pending, (state, _action) => {
        state.alertsStatsApiStatus = APIStatus.PENDING;
      })
      .addCase(getFirstAlertsStats.fulfilled, (state, action) => {
        state.alertDashboardData = action.payload;
        state.alertDashboardData.start_date = state.alertDashboardParams.start_date;
        state.alertDashboardData.end_date = state.alertDashboardParams.end_date;
        state.alertsStatsApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getFirstAlertsStats.rejected, (state, _action) => {
        state.alertsStatsApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchAdminFirstAlertsCommunitiesForForm.fulfilled, (state, action) => {
        state.firstAlertsCommunities = action.payload;
      })
      .addCase(fetchDartDeadline.fulfilled, (state, action) => {
        state.dartEndTimestamp = action.payload;
      });
  },
});

export const { handleAlertsTableChange, clearFilter, showAlertModal, closeAlertModal, handleParamsChange } =
  alertSlice.actions;
