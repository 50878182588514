import { Spin } from 'antd';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import 'antd/dist/reset.css';

import routerWindow from './components/sharedComponents/CustomPrompt/routerWindow';
import ErrorBoundary from './errorBoundary';
import { routes } from './routes/routes';

const router = createHashRouter(routes, { window: routerWindow });

function App() {
  return (
    <ErrorBoundary>
      <RouterProvider router={router} fallbackElement={<Spin style={{ marginTop: '25%' }} />} />
    </ErrorBoundary>
  );
}

export default App;
