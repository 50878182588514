import type { TablePaginationConfig } from 'antd';
import { notification } from 'antd';
import type { SorterResult, SortOrder } from 'antd/lib/table/interface';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

type BooleanStr = 'true' | 'false';

export type GuestFeedback = {
  id: string;
  chat_id: string;
  timestamp: string;
  source: 'Chat' | 'Feedback';
  feedback: string | null;
  training: BooleanStr | null;
  advocate_name: string;
  email: string;
  first_name: string;
  total_count: number;
  rating: string;
  helpful: BooleanStr | null;
  guest_id: string;
  org_name: string;
};

type GuestFeedbackParam = {
  pagination: TablePaginationConfig;
  sortedInfo: GuestFeedbackSortedInfo;
  filteredInfo: FilteredInfo;
};

export class GuestFeedbackSortedInfo implements SorterResult<GuestFeedback> {
  columnKey: keyof GuestFeedback = 'timestamp';
  field: keyof GuestFeedback = 'timestamp';
  order: SortOrder = 'descend';
}

type GuestFeedbackSliceType = {
  guestFeedback: GuestFeedback[];
  guestFeedbackParam: GuestFeedbackParam;
  apiStatus: APIStatus;
};

type Rating = {
  id: string;
  value: string;
};

export type FeedbackContent = {
  guest_id: string;
  chat_id: string;
  email_address: string;
  feedback: string;
};

const initialState: GuestFeedbackSliceType = {
  guestFeedback: [],
  guestFeedbackParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new GuestFeedbackSortedInfo(),
    filteredInfo: null,
  },
  apiStatus: APIStatus.IDLE,
};

export const changeGuestFeedbackRating = createAsyncThunk<string, Rating, AsyncThunkConfig>(
  'guestFeedback/changeGuestFeedbackRating',
  async (rating: Rating, thunkAPI) => {
    try {
      const response = await axios.post('/v0_set_guest_feedback_rating', rating);
      thunkAPI.dispatch(fetchGuestFeedback());
      notification.success({
        message: 'Success',
        description: 'Rating has been sucessfully changed.',
      });
      return response as unknown as string; // id
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
      logError(e, ['guestFeedbackSlice', 'changeGuestFeedbackRating']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const logEmailFeedbackClicked = createAsyncThunk<number, FeedbackContent, AsyncThunkConfig>(
  'guestFeedback/logEmailFeedbackClicked',
  async (feedbackContent, thunkAPI) => {
    try {
      const response = await axios.post('/v0_log_feedback_email', feedbackContent);
      return response as unknown as number; // id
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
      logError(e, ['guestFeedbackSlice', 'logEmailFeedbackClicked']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);
export const fetchGuestFeedback = createAsyncThunk<GuestFeedback[], undefined, AsyncThunkConfig>(
  'guestFeedback/fetchGuestFeedback',
  async (_, thunkAPI) => {
    try {
      const { guestFeedbackSlice } = thunkAPI.getState();
      const response = await axios.post('v0_get_guest_feedback', guestFeedbackSlice.guestFeedbackParam);
      return response as unknown as GuestFeedback[];
    } catch (e) {
      logError(e, ['guestFeedbackSlice', 'fetchGuestFeedback']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const guestFeedbackSlice = createSlice({
  name: 'guestFeedback',
  initialState,
  reducers: {
    handleFeedbackTableChange: (state, action: PayloadAction<TableChange<GuestFeedback>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as GuestFeedbackSortedInfo;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.guestFeedbackParam.pagination = action.payload.pagination;
      state.guestFeedbackParam.sortedInfo = sorter;
      state.guestFeedbackParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.guestFeedbackParam.pagination = initialState.guestFeedbackParam.pagination;
      state.guestFeedbackParam.sortedInfo = initialState.guestFeedbackParam.sortedInfo;
      state.guestFeedbackParam.filteredInfo = initialState.guestFeedbackParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGuestFeedback.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchGuestFeedback.fulfilled, (state, action) => {
        state.guestFeedback = action.payload;
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchGuestFeedback.rejected, (state, _action) => {
        state.apiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleFeedbackTableChange, clearFilter } = guestFeedbackSlice.actions;
