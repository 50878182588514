import { useCallback, useRef } from 'react';
import type { InputRef } from 'antd';
import { Button, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';

import { SearchOutlined } from '@ant-design/icons';

import { TableDatePickerFilter } from '../components/sharedComponents/tableFilters/RangeDatePickerFilter';
import { useAppDispatch, useAppSelector } from '../hooks';
import type { ClearFilters, Confirm, FilteredInfo, GetColumnSearchProps, SelectedKeys } from '../models/table';
import { isMobile } from '../store';
import { handleResetSearch, handleSearchSlice } from './tableSearchSlice';

export const dateFormatToLocale = (date: Date, month: 'short' | 'long' = 'short'): string => {
  return date.toLocaleString(['en-US', 'en-CA'], {
    month: month,
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};

export const timeFormatToLocale = (date: Date): string => {
  return date.toLocaleString(['en-US', 'en-CA'], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};
export const dateFormatNoTimeToLocale = (date: Date): string => {
  return date.toLocaleString(['en-US', 'en-CA'], {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });
};

export const removeEmpty = (obj: FilteredInfo) => {
  if (!obj) return {};
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  return obj;
};

export const getAccountStatusColor = (account_status: string) => {
  return account_status === 'archived' ? '#8c92ac' : 'green';
};

const dateColumnsTableService = [
  'last_check_in',
  'last_intervention',
  'created',
  'updated_timestamp',
  'date',
  'timestamp',
  'submission_time',
  'start_timestamp',
  'sent_time',
  'blocked_updated',
  'archived',
];

export const TableService = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<InputRef>(null);
  const searchText = useAppSelector((state) => state.tableSearchSlice.searchText);
  const searchedColumn = useAppSelector((state) => state.tableSearchSlice.searchedColumn);

  //search functionality
  const getColumnSearchProps: GetColumnSearchProps = (dataIndex, title, savedSearch?) => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      const filterDropdownComponent = dateColumnsTableService.includes(dataIndex) ? (
        <TableDatePickerFilter
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={() => handleReset(clearFilters, confirm)}
        />
      ) : (
        <div style={{ padding: 8 }}>
          <Input
            ref={ref}
            placeholder={`Search ${title.toLowerCase()}`}
            defaultValue={savedSearch || undefined}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      );
      return filterDropdownComponent;
    },
    filterIcon: (filtered) =>
      isMobile && dateColumnsTableService.includes(dataIndex) ? null : (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => ref.current?.select(), 100);
      }
    },
    render: (text) => {
      // TODO: Check what is happening here to see if can be improved
      const savedSearchText = savedSearch?.[0];
      return searchedColumn === dataIndex || savedSearchText !== undefined ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText || savedSearchText || '']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      );
    },
  });

  const handleSearch = (selectedKeys: SelectedKeys, confirm: Confirm, dataIndex: string) => {
    confirm();
    dispatch(handleSearchSlice({ selectedKeys, dataIndex }));
  };

  const handleReset = (clearFilters: ClearFilters, confirm: Confirm) => {
    clearFilters?.();
    confirm();
    dispatch(handleResetSearch());
  };

  const clearSearch = useCallback(() => {
    dispatch(handleResetSearch());
  }, [dispatch]);

  return { getColumnSearchProps, clearSearch };
};
