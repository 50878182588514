export enum APIStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum Role {
  SUPER_ADMIN = 'super_admin',
  EXTERNAL_ADMIN = 'external_admin',
  ALERT_DISPATCHER = 'alert_dispatcher',
  ALERT_MANAGER = 'alert_manager',
  DASHBOARD_ONLY = 'dashboard',
  DIRECTOR = 'director',
  MANAGER = 'manager',
  SERVICE_PROVIDER_MANAGER = 'service_provider_manager',
}

export const RoleLabel = {
  [Role.SUPER_ADMIN]: 'Super admin',
  [Role.EXTERNAL_ADMIN]: 'External admin',
  [Role.DIRECTOR]: 'Director',
  [Role.MANAGER]: 'Manager',
  [Role.ALERT_MANAGER]: 'FirstAlerts manager',
  [Role.ALERT_DISPATCHER]: 'FirstAlerts dispatcher',
  [Role.DASHBOARD_ONLY]: 'Dashboard only',
  [Role.SERVICE_PROVIDER_MANAGER]: 'Service provider manager',
} as const;

export enum Org {
  Saskatchewan = 'sk',
  Test = 'test',
  BurnsWay = 'burns',
}

export enum ManagerPage {
  RegionalManager = 'RegionalManager',
  FirstAlertsManager = 'FirstAlertsManager',
  ServiceProviderManager = 'ServiceProviderManager',
}

export enum AdvocatePage {
  PeerAdvocate = 'PeerAdvocate',
  ServiceProvider = 'ServiceProvider',
}
