import type { TablePaginationConfig } from 'antd';
import type { SorterResult, SortOrder } from 'antd/lib/table/interface';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus, Org } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type Region = {
  name: string;
  number: number;
};

export const getRegionName = (number: number, regions: Region[]) => {
  return regions.find((r) => r.number === number)?.name || '';
};

export class AdminCommunity {
  id: string;
  name: string;
  treaty_council: string;
  region: string;
  region_number: number;
  total_count: number;
  org_name: string;
  org_code: Org = Org.Saskatchewan;
}

interface RequestAdminCommunityParam {
  pagination: TablePaginationConfig;
  sortedInfo: AdminCommunitySortedInfo;
  filteredInfo: FilteredInfo;
}

export class AdminCommunitySortedInfo implements SorterResult<AdminCommunity> {
  columnKey: keyof AdminCommunity = 'name';
  field: keyof AdminCommunity = 'name';
  order: SortOrder = 'ascend';
}

type AdminLanguage = {
  language_code: string;
  language_name: string;
};

type AdminCommunitySliceType = {
  adminCommunitiesData: AdminCommunity[];
  regions: Region[];
  languages: AdminLanguage[];
  requestAdminCommunitiesParam: RequestAdminCommunityParam;
  adminComunitiesApiStatus: APIStatus;
};

const initialState: AdminCommunitySliceType = {
  adminCommunitiesData: [],
  regions: [],
  languages: [],
  requestAdminCommunitiesParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new AdminCommunitySortedInfo(),
    filteredInfo: null,
  },
  adminComunitiesApiStatus: APIStatus.IDLE,
};

export const fetchAdminCommunities = createAsyncThunk<AdminCommunity[], undefined, AsyncThunkConfig>(
  'adminCommunity/fetchAdminCommunities',
  async (_, thunkAPI) => {
    try {
      const { requestAdminCommunitiesParam } = thunkAPI.getState().adminCommunitySlice;
      const response = (await axios.post('v0_get_communities', requestAdminCommunitiesParam)) as AdminCommunity[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminCommunitySlice', 'fetchAdminCommunities']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllRegions = createAsyncThunk<Region[], undefined, AsyncThunkConfig>(
  'adminCommunity/fetchAllRegions',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v0_get_all_regions')) as Region[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminCommunitySlice', 'fetchAllRegions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminCommunitySlice = createSlice({
  name: 'adminCommunity',
  initialState,
  reducers: {
    handleCommunitysTableChange: (state, action: PayloadAction<TableChange<AdminCommunity>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as AdminCommunitySortedInfo;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminCommunitiesParam.pagination = action.payload.pagination;
      state.requestAdminCommunitiesParam.sortedInfo = sorter;
      state.requestAdminCommunitiesParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminCommunitiesParam.pagination = initialState.requestAdminCommunitiesParam.pagination;
      state.requestAdminCommunitiesParam.sortedInfo = initialState.requestAdminCommunitiesParam.sortedInfo;
      state.requestAdminCommunitiesParam.filteredInfo = initialState.requestAdminCommunitiesParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminCommunities.pending, (state, _action) => {
        state.adminComunitiesApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminCommunities.fulfilled, (state, action) => {
        state.adminCommunitiesData = action.payload;
        state.adminComunitiesApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminCommunities.rejected, (state, _action) => {
        state.adminComunitiesApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchAllRegions.pending, (state, _action) => {
        state.adminComunitiesApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAllRegions.fulfilled, (state, action) => {
        state.regions = action.payload;
        state.adminComunitiesApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllRegions.rejected, (state, _action) => {
        state.adminComunitiesApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleCommunitysTableChange, clearFilter } = adminCommunitySlice.actions;
