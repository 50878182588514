import type { LabeledValue } from 'antd/lib/select';

export const datesRange: LabeledValue[] = [
  {
    label: '1 Week',
    value: '1 week',
  },
  {
    label: '2 Weeks',
    value: '2 weeks',
  },
  {
    label: '3 Weeks',
    value: '3 weeks',
  },
  {
    label: '1 Month',
    value: '1 month',
  },
];

export const platformOptions: LabeledValue[] = [
  {
    label: 'iOS',
    value: 'iOS',
  },
  {
    label: 'Android',
    value: 'Android',
  },
];

type CommentOption = LabeledValue & {
  platform: string;
};

export const commentOptions: CommentOption[] = [
  {
    label: 'User acquisition (All users, All events, Per interval, Daily): All countries / regions',
    value: 'User acquisition (All users, All events, Per interval, Daily): All countries / regions',
    platform: 'Android',
  },
  {
    label: 'Total downloads',
    value: 'Total downloads',
    platform: 'iOS',
  },
];

export const dateTimeSubmitFormat = 'YYYY-MM-DDTHH:mm:ss';
