import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Org } from '../../../enum';
import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { type FilteredInfo, type RequestParams, SortedInfo, type TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { validateAdminFirstAlertsCommunity } from './admin-first-alerts-communities.helper';

const { logError } = RaygunErrorHandlerService();

export class AdminFirstAlertsCommunity {
  id: string;
  name: string;
  org_name: string;
  org_code: Org = Org.Saskatchewan;
  org_use_dart: boolean;
  type_id: string;
  type_name: string;
  internal: boolean = true;
  created: string;
  num_guests_subscribed: number;
  num_alert_managers: number;
  total_count: number;
}

export type FirstAlertsCommunityType = {
  id: string;
  name: string;
  org_code: Org;
};

export type Organization = {
  org_name: string;
  org_code: Org;
};

export enum ADMIN_FIRST_ALERTS_COMMUNITIES_MODAL {
  IDLE = 'idle',
  EDIT = 'edit',
}

type AdminFirstAlertsCommunitiesSliceType = {
  adminFirstAlertsCommunities: AdminFirstAlertsCommunity[];
  adminFirstAlertsCommunitiesForSendAlert: AdminFirstAlertsCommunity[];
  adminFirstAlertsCommunityTypes: FirstAlertsCommunityType[];
  adminFirstAlertsCommunityOrgs: Organization[];
  requestParams: RequestParams<AdminFirstAlertsCommunity>;
  adminFirstAlertsComunitiesApiStatus: APIStatus;
  adminFirstAlertsCommunitiesModal: ADMIN_FIRST_ALERTS_COMMUNITIES_MODAL;
  adminFirstAlertsCommunity: AdminFirstAlertsCommunity;
};

const initialState: AdminFirstAlertsCommunitiesSliceType = {
  adminFirstAlertsCommunities: [],
  adminFirstAlertsCommunitiesForSendAlert: [],
  adminFirstAlertsCommunityTypes: [],
  adminFirstAlertsCommunityOrgs: [],
  requestParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminFirstAlertsCommunity>('name', 'ascend'),
    filteredInfo: {},
  },
  adminFirstAlertsComunitiesApiStatus: APIStatus.IDLE,
  adminFirstAlertsCommunitiesModal: ADMIN_FIRST_ALERTS_COMMUNITIES_MODAL.IDLE,
  adminFirstAlertsCommunity: new AdminFirstAlertsCommunity(),
};

export const fetchAdminFirstAlertsCommunities = createAsyncThunk<
  AdminFirstAlertsCommunity[],
  undefined,
  AsyncThunkConfig
>('adminFirstAlertsCommunities/fetchAdminFirstAlertsCommunities', async (_, thunkAPI) => {
  try {
    const { requestParams } = thunkAPI.getState().adminFirstAlertCommunitySlice;
    const response = (await axios.post(
      'v0_get_admin_first_alerts_communities',
      requestParams,
    )) as AdminFirstAlertsCommunity[];
    return response ?? [];
  } catch (e) {
    logError(e, ['adminFirstAlertsCommunitiesSlice', 'fetchAdminFirstAlertsCommunities']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const fetchAdminFirstAlertsCommunitiesForSendAlert = createAsyncThunk<
  AdminFirstAlertsCommunity[],
  undefined,
  AsyncThunkConfig
>('adminFirstAlertsCommunities/fetchAdminFirstAlertsCommunitiesForSendAlert', async (_, thunkAPI) => {
  try {
    const response = (await axios.post('v0_get_admin_first_alerts_communities')) as AdminFirstAlertsCommunity[];
    return response ?? [];
  } catch (e) {
    logError(e, ['adminFirstAlertsCommunitiesSlice', 'fetchAdminFirstAlertsCommunitiesForSendAlert']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const fetchAdminFirstAlertsCommunityTypes = createAsyncThunk<
  FirstAlertsCommunityType[],
  undefined,
  AsyncThunkConfig
>('adminFirstAlertsCommunities/fetchAdminFirstAlertsCommunityTypes', async (_, thunkAPI) => {
  try {
    const { requestParams } = thunkAPI.getState().adminFirstAlertCommunitySlice;
    const response = (await axios.post(
      'v0_get_first_alerts_community_types',
      requestParams,
    )) as FirstAlertsCommunityType[];
    return response ?? [];
  } catch (e) {
    logError(e, ['adminFirstAlertsCommunitiesSlice', 'fetchAdminFirstAlertsCommunityTypes']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const fetchAdminFirstAlertsCommunityOrgs = createAsyncThunk<Organization[], undefined, AsyncThunkConfig>(
  'adminFirstAlertsCommunities/fetchAdminFirstAlertsCommunityOrgs',
  async (_, thunkAPI) => {
    try {
      const { requestParams } = thunkAPI.getState().adminFirstAlertCommunitySlice;
      const response = (await axios.post('v0_get_admin_first_alerts_community_orgs', requestParams)) as Organization[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminFirstAlertsCommunitiesSlice', 'fetchAdminFirstAlertsCommunityOrgs']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

class AdminFirstAlertCommunityUpsertResponse {
  first_alert_community_id: string = '';
  is_update: boolean = false;
}

export const upsertAdminFirstAlertsCommunity = createAsyncThunk<
  AdminFirstAlertCommunityUpsertResponse,
  AdminFirstAlertsCommunity,
  AsyncThunkConfig
>('adminFirstAlertsCommunities/upsertAdminFirstAlertsCommunity', async (upsertAdminFirstAlertsCommunity, thunkAPI) => {
  try {
    if (validateAdminFirstAlertsCommunity(upsertAdminFirstAlertsCommunity)) {
      const response = (await axios.post(
        'v0_admin_first_alerts_community_upsert',
        upsertAdminFirstAlertsCommunity,
      )) as AdminFirstAlertCommunityUpsertResponse;
      if (response) {
        thunkAPI.dispatch(fetchAdminFirstAlertsCommunities());
        thunkAPI.dispatch(closeAdminFirstAlertsCommunitiesModal());
        notification.success({
          message: `FirstAlerts community ${response.is_update ? 'updated' : 'added'} successfully`,
          description: 'Success',
        });
        return response;
      }
    }
    return new AdminFirstAlertCommunityUpsertResponse();
  } catch (e) {
    logError(e, ['adminFirstAlertsCommunitiesSlice', 'upsertAdminFirstAlertsCommunity']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const adminFirstAlertsCommunitiesSlice = createSlice({
  name: 'adminFirstAlertsCommunities',
  initialState,
  reducers: {
    handleFirstAlertsCommunitiesTableChange: (state, action: PayloadAction<TableChange<AdminFirstAlertsCommunity>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminFirstAlertsCommunity>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestParams.pagination = action.payload.pagination;
      state.requestParams.sortedInfo = sorter;
      state.requestParams.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestParams.pagination = initialState.requestParams.pagination;
      state.requestParams.sortedInfo = initialState.requestParams.sortedInfo;
      state.requestParams.filteredInfo = initialState.requestParams.filteredInfo;
    },
    showEditModal: (state, action: PayloadAction<ADMIN_FIRST_ALERTS_COMMUNITIES_MODAL>) => {
      state.adminFirstAlertsCommunitiesModal = action.payload;
    },
    setAdminFirstAlertsCommunity: (state, action: PayloadAction<AdminFirstAlertsCommunity>) => {
      state.adminFirstAlertsCommunity = action.payload;
    },
    closeAdminFirstAlertsCommunitiesModal: (state) => {
      state.adminFirstAlertsCommunitiesModal = ADMIN_FIRST_ALERTS_COMMUNITIES_MODAL.IDLE;
      state.adminFirstAlertsCommunity = new AdminFirstAlertsCommunity();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminFirstAlertsCommunities.pending, (state, _action) => {
        state.adminFirstAlertsComunitiesApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminFirstAlertsCommunities.fulfilled, (state, action) => {
        state.adminFirstAlertsCommunities = action.payload;
        state.adminFirstAlertsComunitiesApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminFirstAlertsCommunities.rejected, (state, _action) => {
        state.adminFirstAlertsComunitiesApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchAdminFirstAlertsCommunityTypes.fulfilled, (state, action) => {
        state.adminFirstAlertsCommunityTypes = action.payload;
      })
      .addCase(fetchAdminFirstAlertsCommunityOrgs.fulfilled, (state, action) => {
        state.adminFirstAlertsCommunityOrgs = action.payload;
      })
      .addCase(fetchAdminFirstAlertsCommunitiesForSendAlert.fulfilled, (state, action) => {
        state.adminFirstAlertsCommunitiesForSendAlert = action.payload;
      });
  },
});

export const {
  handleFirstAlertsCommunitiesTableChange,
  clearFilter,
  showEditModal,
  setAdminFirstAlertsCommunity,
  closeAdminFirstAlertsCommunitiesModal,
} = adminFirstAlertsCommunitiesSlice.actions;
