import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MIN_MANAGER_PWD_CHAR_REQUIRED } from '../../../constants';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { AdvocateUpsert } from '../../pages/advocate-upsert/advocateUpsertSlice';

const { logError } = RaygunErrorHandlerService();

type ResetAdvocatePasswordSliceType = {
  showModal: boolean;
  advocate: AdvocateUpsert;
  loading: boolean;
};

const initialState: ResetAdvocatePasswordSliceType = {
  showModal: false,
  advocate: new AdvocateUpsert(),
  loading: false,
};

export type ResetAdvocatePasswordParams = {
  advocate_id: string;
  password: string;
};

export const adminAdvocatePasswordReset = createAsyncThunk<string, ResetAdvocatePasswordParams, AsyncThunkConfig>(
  'advocateSetTempPassword/adminAdvocatePasswordReset',
  async (resetPassword: ResetAdvocatePasswordParams, thunkAPI) => {
    try {
      if (resetPassword.password.length < MIN_MANAGER_PWD_CHAR_REQUIRED) {
        notification.error({
          message: 'Password too short',
          description: `The password must be at least ${MIN_MANAGER_PWD_CHAR_REQUIRED} characters long.`,
        });
      } else {
        const response = await axios.post('v0_set_advocate_password', resetPassword);
        if (response) {
          notification.success({
            message: 'Success',
            description: 'Password set successfully.',
          });
          thunkAPI.dispatch(closeSetPasswordModal());
          return response as unknown as string; // id
        }
      }
      return '';
    } catch (e) {
      logError(e, ['advocateSetTempPasswordSlice', 'adminAdvocatePasswordReset']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const advocateSetTempPasswordSlice = createSlice({
  name: 'advocateSetTempPassword',
  initialState,
  reducers: {
    showSetPasswordModal: (state, action) => {
      state.advocate = action.payload;
      state.showModal = true;
    },
    closeSetPasswordModal: (state) => {
      state.advocate = new AdvocateUpsert();
      state.showModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminAdvocatePasswordReset.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(adminAdvocatePasswordReset.rejected, (state, _action) => {
        state.loading = false;
      })
      .addCase(adminAdvocatePasswordReset.fulfilled, (state, _action) => {
        state.loading = false;
      });
  },
});

export const { showSetPasswordModal, closeSetPasswordModal } = advocateSetTempPasswordSlice.actions;
